import { NextPage } from "next";
import { useSession } from "next-auth/react";
import { EventTreeTarget } from "~/common/constants";
import { useAnalytics } from "~/components/Analytics";
import {
  HomepageHeaderSection,
  HomepageHeaderSectionProps,
} from "~/components/HomepageHeaderSection";
import {
  HomepageListCtaSection,
  HomepageListCtaSectionProps,
} from "~/components/HomepageListCtaSection";
import {
  HomepageTabbedGridSection,
  HomepageTabbedGridSectionProps,
} from "~/components/HomepageTabbedGridSection";
import { PageMeta, PostMetadata } from "~/components/PageMeta";
import { createClickEventTreeHandler } from "~/lib/client/createEventTreeHandlers";

export type HomepageProps = {
  headerSectionProps: HomepageHeaderSectionProps;
  listCtaSectionProps: HomepageListCtaSectionProps;
  tabbedGridSectionProps: HomepageTabbedGridSectionProps;
  meta: PostMetadata;
};

export const Homepage: NextPage<HomepageProps> = ({
  meta,
  headerSectionProps,
  listCtaSectionProps,
  tabbedGridSectionProps,
}) => {
  const { status } = useSession();
  const hasAccess = status === "authenticated";

  const { analyticsEnhancedTrack } = useAnalytics();

  // Sneaky way to get around ESLint mutation errors ;)
  Object.assign(headerSectionProps.homepageSearchOrCTAProps, { hasAccess });
  Object.assign(listCtaSectionProps, { hasAccess });

  const clickHandler = createClickEventTreeHandler(
    [
      EventTreeTarget.CONTENT_ITEM_HEADLINE_LINK,
      EventTreeTarget.CONTENT_ITEM_IMAGE_LINK,
      EventTreeTarget.CONTENT_ITEM_KICKER_LINK,
      EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_AUTHENTICATED,
      EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_UNAUTHENTICATED,
      EventTreeTarget.HOMEPAGE_SUBSCRIPTION_BLOCK,
      EventTreeTarget.HOMEPAGE_TABBED_GRID_BUTTON,
    ],
    (matchingOrigin: EventTreeTarget, matchingElement: HTMLElement) => {
      switch (matchingOrigin) {
        case EventTreeTarget.CONTENT_ITEM_HEADLINE_LINK:
        case EventTreeTarget.CONTENT_ITEM_IMAGE_LINK:
          analyticsEnhancedTrack("Homepage: Click Into Content", {
            headline: matchingElement.getAttribute("data-analytics-headline"),
          });
          break;

        case EventTreeTarget.CONTENT_ITEM_KICKER_LINK:
          analyticsEnhancedTrack("Homepage: Click Content Kicker", {
            kicker: matchingElement.textContent,
          });
          break;

        case EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_AUTHENTICATED:
          analyticsEnhancedTrack("Homepage: Searchscribe (Authenticated)", {
            buttonText: matchingElement.textContent,
          });
          break;

        case EventTreeTarget.HOMEPAGE_SEARCH_OR_CTA_UNAUTHENTICATED:
          analyticsEnhancedTrack("Homepage: Searchscribe (Unauthenticated)", {
            buttonText: matchingElement.textContent,
          });
          break;

        case EventTreeTarget.HOMEPAGE_SUBSCRIPTION_BLOCK:
          analyticsEnhancedTrack("Homepage: Subscription Block", {
            targetLink: matchingElement.getAttribute("href"),
          });
          break;

        default: // default is EventTreeTarget.HOMEPAGE_TABBED_GRID_BUTTON
          analyticsEnhancedTrack("Homepage: Coverage Area Toggle", {
            sectionTitle: matchingElement.textContent,
          });
          break;
      }
    }
  );

  /* We don't need to handle keyboard events below because we are only tracking interactive 
  elements (anchor/button). Interactive elements generate a click event when enter is pressed. */
  return (
    <>
      <PageMeta {...meta} />
      <div className="md:py-l lg:pb-xxl lg:pt-xl" onClick={clickHandler}>
        <HomepageHeaderSection {...headerSectionProps} />
        <HomepageListCtaSection {...listCtaSectionProps} />
        <HomepageTabbedGridSection {...tabbedGridSectionProps} />
      </div>
    </>
  );
};
