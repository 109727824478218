import { GetStaticProps } from "next";
import { Homepage, HomepageProps } from "~/components/Homepage";
import { apiToHomepageHeaderSectionProps } from "~/components/HomepageHeaderSection";
import { apiToHomepageListCtaSectionProps } from "~/components/HomepageListCtaSection";
import { apiToHomepageTabbedGridSectionProps } from "~/components/HomepageTabbedGridSection";
import {
  fetchHomepage,
  fetchHomepagePreview,
} from "~/lib/server/asgardApiCalls";
import { wrapGetStaticProps } from "~/lib/server/wrapGetProps";

export const getStaticProps: GetStaticProps<HomepageProps> = wrapGetStaticProps(
  async ({ preview }) => {
    const fetchMethod = preview ? fetchHomepagePreview : fetchHomepage;
    const { data } = await fetchMethod();

    return {
      props: {
        headerSectionProps: apiToHomepageHeaderSectionProps(data.hero),
        listCtaSectionProps: apiToHomepageListCtaSectionProps(data.subscribe),
        tabbedGridSectionProps: apiToHomepageTabbedGridSectionProps(
          data.latest
        ),
        meta: {
          seo: data.seo,
          structuredData: data.structuredData,
          piano: {
            type: "simple",
          },
        },
      } satisfies HomepageProps,
    };
  }
);

export default Homepage;
